import {NextSeoProps, OpenGraph} from 'next-seo/lib/types.d';

/**
 * Helper function that merges our default props with the ones given.
 *
 * If some of our default SEO props are not included in props, this function injects it.
 *
 * NOTE: Our default SEO props are
 *
 * title: t('metas:title')
 * description: t('metas:description')
 * openGraph.title: t('metas:og-title')
 * openGraph.description: t('metas:og-og-description')
 *
 * NOTE: We need to pass the translation function as an argument in order to not violate the rule of hooks
 *
 * @param props
 * @param t: Translation function
 * @constructor
 */
export const SEOPropsMerger = (
    props: NextSeoProps,
    t: (str) => string
): NextSeoProps => {
    const {title, description, openGraph} = props;
    const _title = title ? title : t('metas:title');
    const _description = description ? description : t('metas:description-key');
    let _openGraph: OpenGraph;
    if (openGraph) {
        const _openGraphTitle = openGraph.title
            ? openGraph.title
            : t('metas:og-title');
        const _openGraphDescription = openGraph.description
            ? openGraph.description
            : t('metas:og-description');
        _openGraph = {
            ...openGraph,
            ...{title: _openGraphTitle, description: _openGraphDescription},
        };
    } else {
        _openGraph = {
            title: t('metas:og-title'),
            description: t('metas:og-description'),
        } as OpenGraph;
    }
    return {
        ...props,
        ...{title: _title, description: _description, openGraph: _openGraph},
    };
};
