import {styled} from 'styled-components';
import Centered from './centered';

interface Props {
    fontSize?: number;
}

/**
 * Base component for all our headings. It inherits from Centered component
 */
const BaseHeading = styled(Centered)<Props>`
    font-weight: bold;
    letter-spacing: normal;
`;

/**
 * Component to render styled h1 headings
 */
const H1 = styled(BaseHeading).attrs(() => ({as: 'h1'}))`
    font-size: ${(props): string =>
        props.fontSize === undefined
            ? `${props.theme.fontSizes.xLarge}${props.theme.fontMeasureUnit}`
            : `${props.fontSize}${props.theme.fontMeasureUnit}`};
`;

/**
 * Component to render styled h2 headings
 */
const H2 = styled(BaseHeading).attrs(() => ({as: 'h2'}))`
    font-size: ${(props): string =>
        props.fontSize === undefined
            ? `${props.theme.fontSizes.large}${props.theme.fontMeasureUnit}`
            : `${props.fontSize}${props.theme.fontMeasureUnit}`};
`;

const H3 = styled(BaseHeading).attrs(() => ({as: 'h3'}))`
    font-size: ${(props): string =>
        props.fontSize === undefined
            ? `${props.theme.fontSizes.medium}${props.theme.fontMeasureUnit}`
            : `${props.fontSize}${props.theme.fontMeasureUnit}`};
`;

export {H1, H2, H3};
