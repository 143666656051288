import React from 'react';
import {styled} from 'styled-components';
import {StyledProps, mediaQueries} from '../modules/common';
import {ClickableSvg} from './clickable-svg/clickable-svg';

/**
 * Markup for project's base logo
 * This component its now project agnostic (Fonoma or teloa)
 * The logo its get from theme
 */
interface BaseLogoProps extends StyledProps {
    target?: '_self' | '_blank';
}

const SVGLogo = styled.div`
    background-image: url(${(props) => props.theme.logo.backgroundImage});
    width: 100%;
    background-repeat: no-repeat;
}
`;

const UnstyledBaseLogo = (props: BaseLogoProps): JSX.Element => {
    const {className, target} = props;

    return (
        <ClickableSvg href="/" target={target} className={className}>
            <SVGLogo />
        </ClickableSvg>
    );
};

/**
 * Component that renders a clickable logo without borders
 */
const BaseLogo = styled(UnstyledBaseLogo)`
    display: flex;
    float: left;
    > div {
        width: auto;
        height: 24px;
        min-width: 130px;
        margin-left: 24px;
        margin-bottom: 6px;
        ${mediaQueries.lessThan('small')`
            width: auto;
            height: 20px;
            margin-left: 16px;
    `};
    }
`;

/**
 * Markup for logo inside a div
 */
const UnstyledLogo = (props: BaseLogoProps): JSX.Element => {
    const {className, target} = props;
    return (
        <div className={className}>
            <BaseLogo target={target} />
        </div>
    );
};

/**
 * Renders a clickable logo with some space around
 */
const Logo = styled(UnstyledLogo)`
    margin: 0;
    float: left;
    align-self: center;

    ${mediaQueries.lessThan('small')`
       margin-left: 8px;
  `};
`;

export {Logo, BaseLogo};
