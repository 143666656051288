import React from 'react';
import {StyledProps} from '../modules/common';
import {styled} from 'styled-components';
import {LinkButton} from './button';

/**
* Component to display a button that shows a loading image after click

***Props
* onClickHandler: method to execute when clicking the button.
    -If the method return type is void the button will mantain the loading image, this is useful when redirecting the page after click
    -If the method returns true the button will hide the loading image after onClickHandler has finished
* children: components to display inside button
* loadingGifUrl: url of image to display when loading

**/

interface Props extends StyledProps {
    onClickHandler: () => boolean | void;
    children: React.ReactNode;
    loadingGifUrl: string;
}

interface State {
    loading: boolean;
}

const HideableImg = styled.img`
    height: 16px;
    width: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
`;

class LoaderButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleOnButtonClick = (): void => {
        this.setState({loading: true});

        const {onClickHandler} = this.props;

        const result = onClickHandler();
        if (result) {
            this.setState({loading: false});
        }
    };

    componentDidMount(): void {
        window.addEventListener('pageshow', (e: PageTransitionEvent): void => {
            if (e.persisted) {
                this.setState({loading: false});
            }
        });
    }

    render(): JSX.Element {
        const {className, loadingGifUrl, children} = this.props;
        const {loading} = this.state;

        return (
            <LinkButton
                className={className}
                onClick={this.handleOnButtonClick}
                onTouchEnd={this.handleOnButtonClick}
                disabled={loading}
            >
                {loading ? (
                    <HideableImg
                        src={loadingGifUrl}
                        data-testid="hideable-image"
                    />
                ) : (
                    <section>{children}</section>
                )}
            </LinkButton>
        );
    }
}

export {LoaderButton};
