import {updateUserPreferences} from '../cookie-preferences/update-user-preferences';
import {EVENTS, LANGUAGES} from '../constants';
import Cookie from 'universal-cookie';
import {getUserPreferences} from '../cookie-preferences/get-user-preferences';
import {Analytics} from '../../../lib/analytics/analytics';
import {getAfterLanguageChangeURL} from './i18n-get-url-after-change';

export const changeLanguage: (
    analytics: Analytics,
    pageLang: string
) => Promise<{previousLang: string; currentLang: string}> = async (
    analytics,
    pageLang
) => {
    const cookies = new Cookie(document.cookie);

    const userPrefs = {...getUserPreferences(cookies)};
    const userPreviousLang = userPrefs.lang;

    const newLang =
        pageLang === LANGUAGES.ENGLISH ? LANGUAGES.SPANISH : LANGUAGES.ENGLISH;

    // The user previous lang might be equal to the new language in the following scenario
    // 1- The user has Spanish as its language.
    // 2- It goes to en.phonoma.com.
    // 3- Hit the switch language button.
    if (userPreviousLang !== newLang) {
        userPrefs.lang = newLang;
        updateUserPreferences(cookies, userPrefs);
    }

    await analytics.track(EVENTS.LANGUAGE_CHANGED, {
        previous: pageLang,
        current: newLang,
    });

    const redirectURL = getAfterLanguageChangeURL(window.location.href);

    if (redirectURL === window.location.href) {
        window.location.reload();
    } else {
        window.location.assign(redirectURL);
    }
    return {previousLang: pageLang, currentLang: newLang};
};
