import {LANGUAGES} from '../constants';

export const getAfterLanguageChangeURL = (currentURL: string): string => {
    const _currentURL = new URL(currentURL);
    if (_currentURL.host.startsWith(LANGUAGES.ENGLISH)) {
        const newURL = new URL(
            _currentURL.pathname,
            process.env.NEXT_PUBLIC_SERVER_BASE_URL
        );
        return newURL.toString();
    }
    return currentURL;
};
