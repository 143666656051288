import {styled} from 'styled-components';
import {darken} from 'polished';
import {mediaQueries} from '../modules/common';

interface StyledButtonProps {
    secondary?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    fontColor?: string;
    disabled?: boolean;
}

interface BaseButtonProps extends StyledButtonProps {
    onClick?: () => void;
    /* We need this prop in order to use "as" functionality. See https://github.com/emotion-js/emotion/issues/1137 */
    as?: string;
}

/**
 * Fonoma's base link button.
 */
const LinkButton = styled.button
    .withConfig({
        shouldForwardProp: (prop) =>
            !['backgroundColor', 'borderColor', 'fontColor'].includes(prop),
    })
    .attrs<BaseButtonProps, BaseButtonProps>(
        (
            props
        ): {
            backgroundColor: string;
            borderColor: string;
            fontColor: string;
        } => {
            const mainButtonDefaultBackgroundColor =
                props.theme.colors.plancton;
            const mainButtonDefaultFontColor = props.theme.colors.arroz;
            const secondaryButtonDefaultBackgroundColor = 'transparent';
            const secondaryButtonDefaultFontColor = props.theme.colors.ballena;
            const secondaryButtonDefaultBorderColor =
                props.theme.colors.tiburon;

            const backgroundColor = props.backgroundColor
                ? props.backgroundColor
                : props.secondary
                ? secondaryButtonDefaultBackgroundColor
                : mainButtonDefaultBackgroundColor;
            const fontColor = props.fontColor
                ? props.fontColor
                : props.secondary
                ? secondaryButtonDefaultFontColor
                : mainButtonDefaultFontColor;
            const borderColor = props.borderColor
                ? props.borderColor
                : props.secondary
                ? secondaryButtonDefaultBorderColor
                : mainButtonDefaultBackgroundColor;
            return {
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                fontColor: fontColor,
            };
        }
    )`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 56px;
    text-align: center;
    box-shadow: none;

    font-weight: bold;
    font-size: ${(props): string =>
        `${props.theme.fontSizes.xMedium}${props.theme.unitsOfMeasure.px}`};
    font-family: ${(props): string => `${props.theme.fontFamily}`};
    letter-spacing: normal;
    line-height: 24px;

    background-color: ${(props): string => props.backgroundColor};
    border: unset;
    color: ${(props): string => props.fontColor};

    text-decoration: none;

    ${(props): string =>
        !props.disabled &&
        `  
      transition: all .3s ease-out;
      
      &:hover, &:focus {
        color: ${darken('0.1', props.fontColor)};
        border-color: ${darken('0.1', props.borderColor)};
        background-color: ${darken('0.1', props.backgroundColor)};
      }
    
      &:hover{
        cursor: pointer;
      }
    
      &:focus {
        outline-style: none;
      }
  `}
    padding: 16px 24px;
    min-width: 296px;

    ${mediaQueries.lessThan('small')`
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    min-width: unset;
  `}
`;

export {LinkButton};
