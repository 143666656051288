import React from 'react';
// eslint-disable-next-line import/named
import {styled, useTheme} from 'styled-components';
import Link from 'next/link';
import {StyledProps, mediaQueries} from '../modules/common';
import AppleIcon from './reviews-io/apple-icon';

interface IcoProps {
    $fillColor: string;
}
const IcoHolder = styled.div<IcoProps>`
    width: auto;
    height: 16px;
    margin: 0.375rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.875rem;
    color: ${(props): string => props.$fillColor};

    > span {
        padding-left: 0.5rem;

        ${mediaQueries.lessThan('small')`
            font-size: 0.75rem;
        `};
    }

    > picture {
        display: flex;
    }
`;

const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    margin: 0 0 1rem;

    ${mediaQueries.lessThan('small')`
        margin-bottom: 0.75rem;
    `};
`;

const AppLink = styled.a`
    text-decoration: none;
`;

interface AppScoreProps extends StyledProps {
    appleScore: number;
    playScore: number;
    fillColor?: string;
}

const MOBILE_APP_URL = process.env.NEXT_PUBLIC_MOBILE_APP_URL;
const AppScoreWidget = (props: AppScoreProps): JSX.Element => {
    const theme = useTheme();
    const color = props.fillColor
        ? props.fillColor
        : theme.reviewsIO.widgetTextColor;

    return (
        <Container className="appScoreWidget">
            <Link href={MOBILE_APP_URL} passHref legacyBehavior>
                <AppLink>
                    <IcoHolder $fillColor={color}>
                        <picture>
                            <AppleIcon fillColor={color} />
                        </picture>
                        <span>
                            App Store <b>{props.appleScore}</b>
                        </span>
                    </IcoHolder>
                </AppLink>
            </Link>
            <Link href={MOBILE_APP_URL} passHref legacyBehavior>
                <AppLink>
                    <IcoHolder $fillColor={color}>
                        <picture>
                            <img
                                src="/static/landings/miscellanea/play-store-ico.svg"
                                alt={'play-store-img'}
                            />
                        </picture>
                        <span>
                            Play Store <b>{props.playScore}</b>
                        </span>
                    </IcoHolder>
                </AppLink>
            </Link>
        </Container>
    );
};

export {AppScoreWidget};
