import React from 'react';
import {StyledProps, ThemedProps} from '../../modules/common';
import {styled} from 'styled-components';
import {LANGUAGES} from '../../modules/util/constants';

interface ReviewsIoRatingBarWidgetProps extends StyledProps, ThemedProps {
    lang?: string;
    widgetId: string;
    storeName: string;
}

const UnstyledReviewsIoRatingBarWidget = (
    props: ReviewsIoRatingBarWidgetProps
) => {
    const lang = props.lang ? props.lang : LANGUAGES.ENGLISH;
    const {className} = props;

    return (
        <div className={className}>
            <div
                className="reviews-io-rating-bar-widget"
                data-widget-id={props.widgetId}
                data-store-name={props.storeName}
                lang={lang}
            ></div>
        </div>
    );
};

const ReviewsIoRatingBarWidget = styled(UnstyledReviewsIoRatingBarWidget)`
    max-width: 100vw;
    min-height: 24px;
`;

export default ReviewsIoRatingBarWidget;
